<template>
  <div class="logo">
    <img src="./../public//ideas-to-build-high-resolution-logo-transparent.png" alt="" @click="handleClick">
  </div>
  <HomeSection msg="Ideas to Build"/>
  <AboutUs msg="Ideas to Build"/>
  <OurServices msg="Ideas to Build"/>
  <ContactUs msg="Ideas to Build"/>
</template>

<script>
import AboutUs from './components/AboutUs.vue'
import HomeSection from './components/HomeSection.vue'
import OurServices from './components/OurServices.vue'
import ContactUs from './components/ContactUs.vue'

export default {
  name: 'App',
  components: {
    HomeSection,
    AboutUs,
    OurServices,
    ContactUs
  },

  methods: {
    handleClick() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  }
}
</script>

<style>
#app {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .logo {
    img {
      position: fixed;
      left : 1rem;
      top: 2rem;
      width: 9rem;
      cursor: pointer;

      @media only screen and (max-width: 400px){
        position: relative;
        left: auto;
      }
    }
  }
}


body {
  margin: 0px;
}
</style>
