<template>
  <div class="about">
      <h2 class="title">
        <span>ABOUT U</span>
        <span class="special-s">ṩ</span>
      </h2>
      <div class="main-text">
        <div class="main-text-1">
          Welcome to Ideas to Build! We specialize in turning your ideas into reality through comprehensive development services. Whether it's groundbreaking applications, user-friendly websites, or visually stunning 3D designs, we're dedicated to transforming your concepts into tangible realities.
        </div>
        <div class="main-text-2">
          Embark on design at Ideas to Build, beyond web and app development with us. Visualize your dream home with our captivating 3D designs—elegant exteriors, thoughtful interiors. Join us on this journey where innovation meets excellence, turning your concepts into extraordinary experiences.
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'homeSection',

  props: {
    msg: String
  }
}
</script>

<style>
.about {
  padding: 60px;
  min-height: 35vh;
  text-align: center;

  .title {
    margin-top: 0px;
    position: relative;
    color: rgb(42, 42, 42);
    font-size: 2rem;
    font-weight: 700;
    height: auto;
    line-height: 55.2px;
    overflow-wrap: break-word;
    text-align: center;
    width: auto;
    -webkit-font-smoothing: antialiased;
  }

  .main-text {
    display: inline-block;
    font-size: 1.1rem;
    line-height: 1.15;
    letter-spacing: 0.15em;
    max-width: 70vw;

    @media only screen and (max-width: 768px){
      font-size: 1rem;
    }
  }

  .main-text-1 {
    margin-top: 30px;
  }

  .main-text-2 {
    margin-top: 20px;
  }

  .special-s{
    color: rgb(129, 129, 129);
    font-size: 2.5rem;
  }
}
</style>
