<template>
  <div class="outMissions">
    <h2 class="title">
      <span>OUR SERVICE</span>
      <span class="special-s">ṩ</span>
    </h2>
    <div class="main-text">
      <div class="multi-col">
        <table>
          <tbody>
            <tr>

              <td>
                <h2>
                  Digital Development
                </h2>
                <hr class="styled-hr">
                <div class="services-text" style="padding-right: 30px">
                  <span>
                    Elevate your digital presence with Ideas to Build. Our expert application development goes beyond coding, creating dynamic and functional apps. Specialized in website development, we ensure your online storefront not only attracts but engages users. We focus on crafting immersive experiences that captivate and convert.
                  </span>
                  <span style="font-weight: bold">
                    Start your journey to a standout online presence here.
                  </span>

                </div>
              </td>
              <td class="col-img">
                <img src="./../../public//dev.png" alt="" >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="multi-col">
        <table>
          <tbody>
            <tr>
              <td class="col-img">
                <img src="./../../public//concept.png" alt="" >
              </td>
              <td>
                <h2>
                  Visualize & Build
                </h2>
                <hr class="styled-hr">
                <div class="services-text" style="padding-left: 30px">
                  <span>
                    Elevate your visual storytelling with our 3D design expertise, introducing a new dimension from product modeling to architectural visualizations. Materialize your dream home or building with Ideas to Build. Beyond design, we guide you through planning and construction, transforming concepts into breathtaking structures.
                  </span>

                  <span style="font-weight: bold">
                    Join us in bringing your architectural dreams to life.
                  </span>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'homeSection',

  props: {
    msg: String
  }
}
</script>

<style>
.outMissions {
  padding: 60px;
  min-height: 35vh;
  text-align: center;
  background-color: #F8F8F8;

  .title {
    margin-top: 0px;
    position: relative;
    color: rgb(42, 42, 42);
    font-size: 2rem;
    font-weight: 700;
    height: auto;
    line-height: 55.2px;
    overflow-wrap: break-word;
    text-align: center;
    width: auto;
    -webkit-font-smoothing: antialiased;
  }

  .main-text {
    display: inline-block;
    font-size: 1.1rem;
    line-height: 1.15;
    letter-spacing: 0.15em;
    max-width: 70vw;

    @media only screen and (max-width: 768px){
      font-size: 1rem;
    }
  }

  .main-text-section {
    margin-bottom: 30px;
  }

  .main-text-2 {
    margin-top: 20px;
  }

  .special-s{
    color: rgb(129, 129, 129);
    font-size: 2.5rem;
  }

  .col-img {
    width: 30%;

    @media only screen and (max-width: 1000px){
      display: none;
    }

    @media only screen and (max-width: 1400px){
      padding: 0;
    }


    img {
      max-width: 100%;
      border-radius: 15px;
    }
  }

  /* .col-img-1 {
    width: 40%;
    padding: 60px 40px;
    position: relative;

    img {
      position:  absolute;
      top: 2rem;
      left: 2rem;
      max-width: 90%;
      filter: grayscale(1);
    }
  } */

  .styled-hr {
    margin-top: 3rem;
    margin-bottom: 1rem;
    color: red;
    width:50%;
  }

  .multi-col {
    padding-top: 3rem;
  }

  .services-text {
    font-size: 15px
  }
}
</style>
