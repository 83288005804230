<template>
  <div class="contact">
      <h2 class="title">
        <span>CONTACT</span>
        <span> U</span>
        <span class="special-s">ṩ</span>
      </h2>
        <a href="mailto:ideas.to.build.ltd@gmail.com"><button class="custom-btn btn">Email</button></a>
    </div>
</template>

<script>
export default {
  name: 'contactUs',

  props: {
    msg: String
  }
}
</script>

<style>
.contact {
  padding: 60px;
  text-align: center;

  .title {
    margin-top: 0px;
    position: relative;
    color: rgb(42, 42, 42);
    font-size: 2rem;
    font-weight: 700;
    height: auto;
    line-height: 55.2px;
    overflow-wrap: break-word;
    text-align: center;
    width: auto;
    -webkit-font-smoothing: antialiased;
  }


  .special-s{
    color: rgb(129, 129, 129);
    font-size: 2.5rem;
  }

  .custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
    font-size: 15px;
  }

  .btn {
    border: none;
    color: #000;
  }

  .btn:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    box-shadow:
    -7px -7px 20px 0px #fff9,
    -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
  }

  .btn:hover {
    color: #000;
  }

  .btn:hover:after {
    left: auto;
    right: 0;
    width: 100%;
  }

  .btn:active {
    top: 2px;
  }
}
</style>
