<template>
  <div class="home-section">
    <div class="banner">
      <h2 class="title">
        <span>MODERN & ELEGAN</span>
        <span class="special-t">ṯ</span>
      </h2>
      <div class="sub-title">Simple idea and Beautiful result</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'homeSection',

  props: {
    msg: String
  }
}
</script>

<style>
.home-section {
  background-image: url('./../assets/bg1.jpg');
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 100%;
  height: 100vh;

  .banner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .title {
      color: rgb(42, 42, 42);
      display: inline;
      font-size: 2.5rem;
      font-weight: 700;
      height: auto;
      line-height: 55.2px;
      overflow-wrap: break-word;
      text-align: center;
      width: auto;
      -webkit-font-smoothing: antialiased;
    }

    .sub-title {
      color: rgb(129, 129, 129);
      line-height: 1.15;
      letter-spacing: 0.15em;
    }
  }

  .special-t{
    color: rgb(129, 129, 129);
    font-size: 3rem;
  }
}
</style>
